import cx from 'classnames';
import { defineMessages, navigate, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { siteDefaultLanguage } from '../../../config';
import { GAME } from '../../constants';
import * as s from './Hero.module.css';
import IntroBuyOnMarket from './Intro/IntroBuyOnMarket';
import IntroSellInstantly from './Intro/IntroSellInstantly';
import IntroSellOnMarket from './Intro/IntroSellOnMarket';
import IntroTradeSkin from './Intro/IntroTradeSkin';

const TAB_SLUG = {
  SELL_INSTANTLY: 'sell-instantly',
  SELL_ON_MARKET: 'sell-on-market',
  BUY_SKINS: 'buy-skins',
  TRADE_SKINS: 'trade-skins',
};

const messages = defineMessages({
  sellInstantly: {
    id: 'hero.sellInstantly',
    defaultMessage: 'sell instantly',
  },
  sellOnMarket: {
    id: 'hero.sellOnMarket',
    defaultMessage: 'sell on market',
  },
  buySkins: {
    id: 'hero.buySkins',
    defaultMessage: 'buy skins',
  },
  tradeSkins: {
    id: 'hero.tradeSkins',
    defaultMessage: 'trade skins',
  },
});

const SaleFeePromoPillEN = () => (
  <span className={s.saleFeePromoPill}>lower fee</span>
);

const getTabsByGameAndLocale = (game, locale) => [
  {
    slug: TAB_SLUG.SELL_INSTANTLY,
    name: messages.sellInstantly,
    intro: <IntroSellInstantly game={game} />,
    pill: null,
  },
  {
    slug: TAB_SLUG.SELL_ON_MARKET,
    name: messages.sellOnMarket,
    intro: <IntroSellOnMarket />,
    pill: locale === siteDefaultLanguage && <SaleFeePromoPillEN />,
  },
  {
    slug: TAB_SLUG.BUY_SKINS,
    name: messages.buySkins,
    intro: <IntroBuyOnMarket />,
    pill: null,
  },
  {
    slug: TAB_SLUG.TRADE_SKINS,
    name: messages.tradeSkins,
    intro: <IntroTradeSkin />,
    pill: null,
  },
];

const Hero = ({ game, originalPath, hash }) => {
  const { locale, formatMessage } = useIntl();
  const [tabSlug, setTabSlug] = useState(TAB_SLUG.SELL_INSTANTLY);
  const [wasSwitched, setWasSwitched] = useState(false);

  const tabsByGame = useMemo(
    () => getTabsByGameAndLocale(game, locale),
    [game, locale],
  );

  const navigationClasses = cx(s.navigation, {
    [s.invert]: tabSlug === TAB_SLUG.SELL_ON_MARKET,
    [s.invertForGame]:
      tabSlug === TAB_SLUG.SELL_INSTANTLY &&
      (game === GAME.DOTA2 || game === GAME.TF2),
  });

  useEffect(() => {
    const tabSlugFromHash = tabsByGame
      .map((tab) => tab.slug)
      .find((variant) => variant === hash.replace('#', ''));
    setTabSlug(tabSlugFromHash || TAB_SLUG.SELL_INSTANTLY);
  }, [hash]);

  return (
    <section className={s.root}>
      <nav className={navigationClasses}>
        <ul className={s.navigationList}>
          {tabsByGame.map((tab) => {
            const handleTabSwitch = React.useCallback(() => {
              setWasSwitched(true);
              navigate(`${originalPath}#${tab.slug}`);
            }, []);
            return (
              <li
                key={tab.slug}
                className={s.navigationItem}
                style={
                  tab.slug === TAB_SLUG.SELL_ON_MARKET
                    ? { display: 'none' }
                    : null
                }
              >
                <button
                  id={`hero-${tab.slug}-button`}
                  className={cx(s.navigationButton, {
                    [s.isActive]: tab.slug === tabSlug,
                  })}
                  onClick={handleTabSwitch}
                  type="button"
                >
                  <span>{formatMessage(tab.name)}</span>
                  {tab.pill}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
      <ul className={s.intros}>
        {tabsByGame.map((tab) => (
          <li
            id={tab.slug}
            key={tab.slug}
            className={cx(s.intro, {
              [s.isVisible]: tab.slug === tabSlug,
              [s.isAnimate]: wasSwitched,
            })}
          >
            {tab.intro}
          </li>
        ))}
      </ul>
    </section>
  );
};

Hero.propTypes = {
  game: PropTypes.oneOf(Object.values(GAME)).isRequired,
  originalPath: PropTypes.string.isRequired,
  hash: PropTypes.string,
};

Hero.defaultProps = {
  hash: '',
};

export default Hero;
