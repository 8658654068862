import React from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';

import Section from '../Grid/Section/Section';

import Logo from './images/logo.inline.svg';
import ExternalLinkRegularIcon from './images/external-link-regular.inline.svg';

import * as s from './Market.module.css';

const messages = defineMessages({
  title: {
    id: 'market.title',
    defaultMessage: `buy new skins or sell\nyour own for cash`,
  },
  link: {
    id: 'market.link',
    defaultMessage: 'marketplace',
  },
  collectionTitle: {
    id: 'market.collection.title',
    defaultMessage: 'grow your skin collection',
  },
  collectionDescription: {
    id: 'market.collection.description',
    defaultMessage: `Enter one of the best CS:GO skins marketplaces and discover thousands of skins. Join our ever-growing community of skin traders and collectors and buy the best skins for the best prices. Quick\ntop-ups, 100% secure transactions and friendly customer support!`,
  },
  tradeTitle: {
    id: 'market.trade.title',
    defaultMessage: 'trade skins for cash',
  },
  tradeDescription: {
    id: 'market.trade.description',
    defaultMessage: `Sell your old skins or hot new drops for real money! Buy new skins or pay out your earned balance to the e-wallet of your choice. Name your price and make money on CS:GO skins trading. Your skins, your rules, your money!`,
  },
});

const Market = () => {
  const { formatMessage } = useIntl();

  return (
    <Section id="about-market-section">
      <div className={s.headline}>
        <Logo className={s.logo} />
        <a href="/sign-in?continue=/market" className={s.desktopLink}>
          {formatMessage(messages.link)}
          <ExternalLinkRegularIcon />
        </a>
      </div>
      <p className={s.title}>{formatMessage(messages.title)}</p>
      <ul className={s.entries}>
        <li className={s.entry}>
          <p className={s.entryTitle}>
            {formatMessage(messages.collectionTitle)}
          </p>
          <p className={s.entryDescription}>
            {formatMessage(messages.collectionDescription)}
          </p>
        </li>
        <li className={s.entry}>
          <p className={s.entryTitle}>{formatMessage(messages.tradeTitle)}</p>
          <p className={s.entryDescription}>
            {formatMessage(messages.tradeDescription)}
          </p>
        </li>
      </ul>
      <a href="/sign-in?continue=/market" className={s.mobileLink}>
        {formatMessage(messages.link)}
        <ExternalLinkRegularIcon />
      </a>
    </Section>
  );
};

export default Market;
