import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import buySkinsHeroArtwork from './images/artworks/buy-skins-hero.jpg';
import buySkinsHeroArtwork2x from './images/artworks/buy-skins-hero@2x.jpg';
import * as s from './Intro.module.css';
import {
  IntroButton,
  IntroDescription,
  IntroHeader,
  IntroMethods,
  IntroTitle,
  IntroWrapper,
  MARKET_PAY_PROVIDERS,
  SkinwalletMarketLogo,
} from './IntroUtils';

const messages = defineMessages({
  headline: {
    id: 'intro.buySkins.headline',
    defaultMessage: 'by traders, for traders',
  },
  title: {
    id: 'intro.buySkins.title',
    defaultMessage: `discover the best skin\ndeals on our market`,
  },
  description: {
    id: 'intro.buySkins.description',
    defaultMessage: `Explore thousands of offers and buy top CS:GO skins.\nBecome a part of the best CS:GO skins marketplace.`,
  },
  buttonCaption: {
    id: 'intro.buySkins.ctaCaption',
    defaultMessage: 'buy now',
  },
});

const IntroBuyOnMarket = () => {
  const { formatMessage } = useIntl();

  return (
    <IntroWrapper theme={s.marketBuy}>
      <IntroHeader>
        <IntroTitle
          headline={formatMessage(messages.headline)}
          title={formatMessage(messages.title)}
        />
        <IntroDescription description={formatMessage(messages.description)} />
        <IntroButton
          id="intro-cta-buy-skins"
          caption={formatMessage(messages.buttonCaption)}
          href="/market"
        />
      </IntroHeader>
      <IntroMethods
        methods={MARKET_PAY_PROVIDERS}
        productLogo={<SkinwalletMarketLogo />}
      />
      <LazyLoadImage
        className={s.marketBuyArtwork}
        src={buySkinsHeroArtwork}
        srcSet={`${buySkinsHeroArtwork2x} 2x`}
        alt="Buy skins"
        width={825}
        height={744}
      />
    </IntroWrapper>
  );
};

export default IntroBuyOnMarket;
